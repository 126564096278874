import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { getSuggestion } from "../../../action/suggestion";
import {
  TRACK_AUTOCOMPLETE_CLICK,
  TRACK_SEARCH_SUMBIT,
} from "../../../utils/trackEvent";
import RecomendationItemText from "../HeaderSearch/RecomendationItemText";
import { useStateContext } from "../../../context";
import DropdownSearch from "../DropdownSearch";
import SearchIcon from "@heroicons/react/solid/SearchIcon";
import ChevronLeftIcon from "@heroicons/react/solid/ChevronLeftIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import { Offcanvas } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";

const SearchListingBar = (props) => {
  const { t } = useTranslation("listing");
  const router = useRouter();
  const isSearchPage = router.pathname.includes("/cari");
  const { search, slug } = router.query;

  const searchPageKeyword = slug?.split("-").slice(1).join(" ");

  const [state] = useStateContext();

  const [searchInputValue, setSearchInputValue] = useState(
    search || searchPageKeyword || ""
  );
  const [inputValue, setInputValue] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [tab, setTab] = useState(0);
  const [propertyType, setPropertyType] = useState("Rumah");
  const [lastSearched, setLastSearched] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("lastSearched"));
    setLastSearched(items);
  }, []);

  const debouncedSearch = useDebounce(inputValue, 100);

  // useEffect(async () => {
  //   const {
  //     data: { suggestion },
  //   } = await getSuggestion(inputValue);
  //   setSuggestionList(suggestion);
  // }, []);

  useEffect(async () => {
    const {
      data: { location },
    } = await getSuggestion(debouncedSearch);
    setSuggestionList(location);
  }, [debouncedSearch]);

  const onInputChange = async (e) => {
    if (e.target.value.length > 0) {
      setInputValue(e.target.value);
      setSearchInputValue(e.target.value);
    } else {
      setSuggestionList([]);
      setInputValue(e.target.value);
      setSearchInputValue(e.target.value);
    }
  };

  const handleRouterParam = (value) => {
    // if (!isSearchPage) {
    //   router.query.search = value;
    // }
    let items = JSON.parse(localStorage.getItem("lastSearched")) || [];
    items.unshift(value.object_name);

    localStorage.setItem("lastSearched", JSON.stringify(items));
    router.query.type = tab === 0 ? 1 : 2;
    router.query.property = propertyType;
    router.query.minPrice = state.minPrice === 0 ? null : state.minPrice;
    router.query.maxPrice = state.maxPrice === 0 ? null : state.maxPrice;
    router.query.bedroom = state.bed === 0 ? null : state.bed;
    router.query.bathroom = state.bath === 0 ? null : state.bath;
    router.query.floor = state.floor === 0 ? null : state.floor;
    router.query.minBuild = state.minBuild === 0 ? null : state.minBuild;
    router.query.maxBuild = state.maxBuild === 0 ? null : state.maxBuild;
    router.query.districtName =
      value.object_type === "District" ? value.object_name : null;
    router.query.state =
      value.object_type === "State" ? value.object_name : null;
    router.query.area = value.object_type === "Area" ? value.object_name : null;
    router.query.search =
      value.object_type === "Kata Kunci" ? value.object_name : null;
    var str = "";
    for (var key in router.query) {
      if (router.query[key] !== null) {
        if (key !== "slug") {
          if (str != "") {
            str += "&";
          }
          str += key + "=" + encodeURIComponent(router.query[key]);
        }
      }
    }
    if (isSearchPage) {
      router.push(
        `/cari-${searchInputValue.split(" ").join("-")}${
          str !== "" ? "?" + str : ""
        }`
      );
    } else {
      router.push(`/listing?${str}`);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    const value = inputValue;

    TRACK_SEARCH_SUMBIT(value, {
      from: "home",
    });
    setInputValue("");
    setSearchInputValue(
      suggestionList.length > 0 ? suggestionList[0].object_name : inputValue
    );
    handleRouterParam(
      suggestionList.length > 0
        ? suggestionList[0]
        : {
            object_name: inputValue,
            object_type: "Kata Kunci",
          }
    );
  };

  const onSuggestionClick = (value) => {
    TRACK_AUTOCOMPLETE_CLICK(value, {
      from: "home",
    });
    setInputValue("");
    setSearchInputValue(value.object_name);
    handleRouterParam(value);
    setOpenSearch(false);
  };

  return (
    <div>
      <Offcanvas
        show={openSearch}
        onHide={() => {
          setOpenSearch(false);
        }}
        backdropClassName="z-1"
        placement="start"
      >
        <div className="h-100 d-flex flex-column">
          <Offcanvas.Header className="d-flex gap-2">
            <button
              className="btn text-primary p-0"
              onClick={() => setOpenSearch(false)}
            >
              <ChevronLeftIcon width={36} height={36} />
            </button>

            <input
              id="search-input"
              className="d-md-none flex-grow-1 bg-transparent border-0 focus-none px-0 py-3"
              type="text"
              name="search"
              onChange={onInputChange}
              ref={props.inputEl}
              value={searchInputValue}
              autoComplete="off"
              placeholder={t("common:search_bar.based placeholder")}
              style={{ outline: "none" }}
            />

            {searchInputValue.length > 0 && (
              <button
                className="btn p-0 text-secondary"
                onClick={() => {
                  setSuggestionList([]);
                  setInputValue("");
                  setSearchInputValue("");
                }}
              >
                <XIcon width={24} height={24} />
              </button>
            )}
          </Offcanvas.Header>

          <p className="px-4 fw-bold text-secondary">
            {suggestionList?.length > 0 ? t("location") : t("searched")}{" "}
            {t("related")}
          </p>

          {suggestionList?.length > 0 ? (
            <div className="overflow-auto" style={{ maxHeight: "500px" }}>
              <ul className="list-group d-flex flex-column gap-2">
                {suggestionList.map(function (row) {
                  return (
                    <li key={`recommendation-${row.object_name}`}>
                      <RecomendationItemText
                        value={row}
                        currentInput={inputValue}
                        onClick={() => {
                          onSuggestionClick(row);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            inputValue.length > 0 && (
              <RecomendationItemText
                value={{
                  object_type: "Kata Kunci",
                  object_name: searchInputValue,
                }}
                currentInput={inputValue}
                onClick={() => {
                  onSuggestionClick({
                    object_type: "Kata Kunci",
                    object_name: searchInputValue,
                  });
                }}
              />
            )
          )}
        </div>
      </Offcanvas>

      <form className="position-relative" onSubmit={handleOnSubmit}>
        {props.isLanding ? (
          <div>
            <ul className="nav nav-tabs border-bottom-0">
              {[t("home:hero_section.sell"), t("home:hero_section.rent")].map(
                (type, i) => (
                  <li
                    key={i}
                    className="nav-item flex-grow-1 flex-md-grow-0"
                    onClick={() => setTab(i)}
                  >
                    <div
                      className={`nav-link position-relative rounded-top-4 cursor-pointer ${
                        tab === i
                          ? "active text-primary fw-bold border-end-0 border-start-0 border-end-0 border-bottom-0 border-4 border-warning"
                          : "bg-white bg-opacity-25"
                      }`}
                      style={{
                        backgroundColor: "#6D87B5",
                      }}
                    >
                      <p
                        className={`text-center d-none d-md-block mb-0 ${
                          tab === i ? "text-white" : "text-secondary"
                        }`}
                        style={{ width: "10rem" }}
                      >
                        {type}
                      </p>
                      <p
                        className={`text-center d-block d-md-none mb-0 ${
                          tab === i ? "text-white" : "text-secondary"
                        }`}
                      >
                        {type}
                      </p>

                      {tab === i && (
                        <div
                          className="position-absolute bottom-0 bg-white"
                          style={{ height: "2px", width: "83%" }}
                        />
                      )}
                    </div>
                  </li>
                )
              )}
            </ul>

            <div
              className="rounded-bottom-4 rounded-end-4 px-2 py-4 p-md-4 shadow-lg"
              style={{ backgroundColor: "#6D87B5" }}
            >
              <div className="d-flex flex-column flex-md-row gap-2 align-items-end">
                <div className="w-100 flex-grow-1 d-flex gap-2">
                  <div className="flex-md-grow-1 w-md-25 d-flex flex-column gap-2">
                    <p className="mb-0 text-white fw-bold">
                      {t("home:hero_section.property type")}
                    </p>

                    <select
                      value={propertyType}
                      className="form-select cursor-pointer"
                      onChange={(e) => setPropertyType(e.target.value)}
                      style={{ height: "50px" }}
                    >
                      <option value="Rumah">
                        {t("common:categories.house")}
                      </option>
                      <option value="Ruko">
                        {t("common:categories.shophouse")}
                      </option>
                      <option value="Apartemen">
                        {t("common:categories.apartement")}
                      </option>
                      <option value="Villa">
                        {t("common:categories.villa")}
                      </option>
                      <option value="Tanah">
                        {t("common:categories.land")}
                      </option>
                    </select>

                    {/* <div className="px-3 d-flex gap-2 align-items-center bg-white rounded-2">
                    <i className="bi bi-search text-primary"></i>
                    <input
                      id="search-input"
                      className="flex-grow-1 bg-transparent border-0 focus-none px-0 py-3"
                      type="text"
                      name="search"
                      onChange={onInputChange}
                      ref={props.inputEl}
                      value={searchInputValue}
                      autoComplete="off"
                      placeholder={t("common:search_bar.based placeholder")}
                      style={{ outline: "none", maxHeight: "50px" }}
                    />
                  </div> */}
                  </div>

                  <div className="w-75 d-flex flex-column gap-2">
                    <p className="mb-0 text-white fw-bold">
                      {t("home:hero_section.location")}
                    </p>

                    <div className="px-3 d-flex gap-2 align-items-center bg-white rounded-2">
                      <i className="bi bi-search text-primary"></i>

                      <input
                        id="search-input"
                        className="d-none d-md-block w-100 bg-transparent border-0 focus-none px-0 py-3"
                        type="text"
                        name="search"
                        onChange={onInputChange}
                        ref={props.inputEl}
                        value={searchInputValue}
                        autoComplete="off"
                        placeholder={t("common:search_bar.based placeholder")}
                        style={{ outline: "none", maxHeight: "50px" }}
                        onFocus={() => setIsShow(true)}
                        onBlur={() =>
                          setTimeout(() => {
                            setIsShow(false);
                          }, 100)
                        }
                      />

                      <div
                        className="flex-grow-1 d-flex d-md-none align-items-center px-0 py-3"
                        style={{ height: "50px", width: "120px" }}
                        onClick={() => setOpenSearch(true)}
                      >
                        <p className="d-inline-block text-secondary mb-0 bg-transparent text-truncate">
                          {searchInputValue ||
                            t("common:search_bar.based placeholder")}
                        </p>
                      </div>

                      {searchInputValue.length > 0 && (
                        <button
                          type="button"
                          className="btn p-0 text-secondary"
                          onClick={() => {
                            setSuggestionList([]);
                            setInputValue("");
                            setSearchInputValue("");
                          }}
                        >
                          <XIcon width={24} height={24} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-white btn d-none d-md-block fw-bolder"
                  style={{
                    backgroundColor: "#29B6FF",
                    width: "10.6rem",
                    height: "50px",
                  }}
                >
                  {t("common:search_bar.search")}
                </button>

                <button
                  type="submit"
                  className="w-100 text-white btn d-md-none fw-bolder"
                  style={{ backgroundColor: "#29B6FF", height: "50px" }}
                >
                  {t("common:search_bar.search")}
                </button>
              </div>

              {lastSearched !== null && (
                <div className="mt-3 d-flex flex-column flex-md-row align-items-md-center gap-2">
                  <p className="text-light mb-0">
                    {t("home:hero_section.last searched")} :
                  </p>
                  <div className="d-flex flex-wrap gap-2">
                    {lastSearched?.slice(0, 3).map((item, i) => (
                      <div
                        key={i}
                        className="d-flex gap-2 align-items-center text-light rounded-pill border-1 border border-white px-2 py-1 bg-white bg-opacity-25"
                        style={{ width: "100px" }}
                        onClick={() => handleRouterParam(item)}
                      >
                        <div className="w-25">
                          <SearchIcon width={15} height={15} />
                        </div>
                        <p className="mb-0 text-truncate">{item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <div
              className="flex-grow-1 px-3 d-flex gap-2 align-items-center bg-white rounded-3"
              style={{ border: "2px solid #C4C4C4" }}
            >
              <i className="bi bi-search text-primary"></i>

              <input
                id="search-input"
                className="d-none d-md-block flex-grow-1 bg-transparent border-0 focus-none px-0 py-2 custom-placeholder"
                type="text"
                name="search"
                onChange={onInputChange}
                ref={props.inputEl}
                value={searchInputValue}
                autoComplete="off"
                placeholder={t("common:search_bar.search here")}
                style={{ outline: "none" }}
                onFocus={() => setIsShow(true)}
                onBlur={() =>
                  setTimeout(() => {
                    setIsShow(false);
                  }, 100)
                }
              />

              <div
                className="flex-grow-1 d-flex d-md-none align-items-center px-0 py-3"
                style={{ height: "50px", width: "120px" }}
                onClick={() => setOpenSearch(true)}
              >
                <p className="d-inline-block text-secondary mb-0 bg-transparent text-truncate">
                  {searchInputValue || t("common:search_bar.based placeholder")}
                </p>
              </div>

              {searchInputValue.length > 0 && (
                <button
                  type="button"
                  className="btn p-0 text-secondary"
                  onClick={() => {
                    setSuggestionList([]);
                    setInputValue("");
                    setSearchInputValue("");
                  }}
                >
                  <XIcon width={24} height={24} />
                </button>
              )}
            </div>

            <button
              type="submit"
              className="btn text-white rounded-3 d-flex justify-content-center"
              style={{ backgroundColor: "#29B6FF", width: "15%" }}
            >
              {t("common:search_bar.search")}
            </button>
          </div>
        )}

        {isShow && (
          <div
            className="card position-absolute top-100 w-100 mt-2 d-flex flex-column z-1"
            style={{ zIndex: 99 }}
          >
            <p className="px-4 pt-4 fw-bold text-secondary">
              {suggestionList?.length > 0
                ? t("common:search_bar.related location")
                : t("common:search_bar.related searched")}
            </p>

            {suggestionList?.length > 0 ? (
              <div className="overflow-auto" style={{ maxHeight: "500px" }}>
                <ul className="list-group d-flex flex-column gap-2">
                  {suggestionList.map(function (row) {
                    return (
                      <li key={`recommendation-${row.object_name}`}>
                        <RecomendationItemText
                          value={row}
                          currentInput={inputValue}
                          onClick={() => {
                            onSuggestionClick(row);
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              inputValue.length > 0 && (
                <RecomendationItemText
                  value={{
                    object_type: "Kata Kunci",
                    object_name: searchInputValue,
                  }}
                  currentInput={inputValue}
                  onClick={() => {
                    onSuggestionClick({
                      object_type: "Kata Kunci",
                      object_name: searchInputValue,
                    });
                  }}
                />
              )
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchListingBar;

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
